import * as R from "ramda";
import { sortArticlesByKoodiarvo } from "../services/lomakkeet/utils";

type Koulutus = {
  koodiarvo: string;
  koodisto: {
    koodistoUri: string;
  };
  metadata: {
    [key: string]: {
      nimi: string;
    };
  };
};

type Maarays = {
  koodiarvo: string;
  koodisto: string;
  uuid: string;
};

/**
 * Luo objektin, joka sisältää lupaa koskevia perustietoja.
 * @param koulutukset Taulukollinen tietoja (koodistopalvelusta).
 * @param locale fi | sv
 * @param maaraykset Taulukollinen määräyksiä.
 * @param koodisto Merkkijono, josta käy ilmi koodisto.
 * @param sectionId
 * @returns Objekti, jota voi hyödyntää lomakemerkkauksen luomisessa.
 */
export function getDataForKoulutusList(
  koulutukset: Array<Koulutus>,
  locale: string,
  maaraykset: Array<Maarays>,
  koodisto: string | null = null,
  sectionId: string | null = null
): Record<string, unknown> {
  const relevantitMaaraykset = koodisto
    ? (R.reject(
        R.isNil,
        R.map(maarays => {
          return maarays.koodisto === koodisto ? maarays : null;
        }, maaraykset)
      ) as Array<Maarays>)
    : [];

  const luvassaOlevatKoodiarvot = R.map(
    R.prop("koodiarvo"),
    relevantitMaaraykset
  );

  const sortedKoulutukset: Array<Koulutus> =
    sortArticlesByKoodiarvo(koulutukset);

  return {
    items: sortedKoulutukset.map((koulutus, index) => {
      const maarays = R.find(
        R.propEq("koodiarvo", koulutus.koodiarvo),
        relevantitMaaraykset
      );
      const isInLupa = !!(
        koodisto && R.includes(koulutus.koodiarvo, luvassaOlevatKoodiarvot)
      );

      return {
        code: koulutus.koodiarvo,
        isInLupa,
        isReasoningRequired: index !== koulutukset.length - 1,
        shouldBeChecked:
          isInLupa ||
          (R.isEmpty(luvassaOlevatKoodiarvot) &&
            index === koulutukset.length - 1 &&
            koodisto === koulutus.koodisto.koodistoUri),
        koodisto: koulutus.koodisto,
        sectionId,
        maaraysUuid: maarays ? maarays.uuid : null,
        maarays,
        metadata: koulutus.metadata,
        title: koulutus.metadata[locale].nimi
      };
    })
  };
}
